/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/common/Layout"

// import SectionHeader from "../components/common/SectionHeader"
import SEO from "../components/common/SEO"

import BlogPosts from "../components/content/BlogPosts"
import CTAMain from "../components/content/CTAMain"
import PageHeader from "../components/common/PageHeader"

const BlogPage = ({ data: { prismicBlogPage } }) => {
  const { data } = prismicBlogPage

  return (
    <Layout>
      <SEO
        title={data.title.text}
        description={data.meta_description.text}
        canonicalUrl={data.canonical_url.text}
      />
      <PageHeader
        heading={data.heading.text}
        introduction={data.introduction.html}
      />

      <BlogPosts />

      <CTAMain
        heading={data.cta_heading.text}
        text={data.cta_text.text}
        buttonLabel={data.cta_button_label.text}
        subText={data.cta_sub_text.text}
      />
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query BlogPageQuery {
    prismicBlogPage {
      data {
        title {
          text
        }
        meta_description {
          text
        }
        canonical_url {
          text
        }
        heading {
          text
        }
        introduction {
          html
        }
        cta_heading {
          text
        }
        cta_text {
          text
        }
        cta_button_label {
          text
        }
        cta_sub_text {
          text
        }
      }
    }
  }
`
