/* eslint-disable camelcase */
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"

import Link from "../common/Link"

const BlogPosts = ({ className }) => {
  return (
    <StaticQuery
      query={graphql`
        query BlogPostsQuery {
          allPrismicBlogPost(
            sort: { order: DESC, fields: last_publication_date }
          ) {
            nodes {
              data {
                heading {
                  text
                }
                introduction {
                  text
                }
                manual_last_updated(formatString: "DD/MM/YYYY")
              }
              uid
              first_publication_date(formatString: "DD/MM/YYYY")
              last_publication_date(formatString: "DD/MM/YYYY")
            }
          }
        }
      `}
      render={queryData => {
        return queryData.allPrismicBlogPost.nodes.map(
          ({ uid, data, last_publication_date }) => (
            <div className={className}>
              <Link to={`/${uid}`}>
                <h3>{data.heading.text}</h3>
              </Link>
              <p>{data.introduction.text}</p>
              <footer>
                <Link to={`/${uid}`}>READ MORE</Link>
                <p className="meta">
                  {data.manual_last_updated &&
                    `Last updated: ${data.manual_last_updated ||
                      last_publication_date}`}
                </p>
              </footer>
            </div>
          )
        )
      }}
    />
  )
}

BlogPosts.propTypes = {
  className: PropTypes.string.isRequired,
}

export default styled(BlogPosts)`
  max-width: 600px;
  margin: 2rem auto 10rem;
  padding: 0 2rem;
  text-align: center;

  footer {
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 2px solid ${({ theme }) => theme.colors["gray-300"]};

    a {
      text-decoration: underline;
    }
  }

  .meta {
    font-size: 1.4rem;
    text-transform: uppercase;
  }
`
